// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import compose from 'recompose/compose'
import pure from 'recompose/pure'
import { connect } from 'react-redux'

import isUndefined from 'lodash/isUndefined'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Row from 'antd/lib/row'
import 'antd/lib/row/style/css'

import Col from 'antd/lib/col'
import 'antd/lib/col/style/css'

import Carousel from 'antd/lib/carousel'
import 'antd/lib/carousel/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../components/link'
import '../components/link/style.less'

import DiagramOfJoy from '../components/diagram-of-joy'
import '../components/diagram-of-joy/style.less'

import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import AuraNetworkAppOverviewFeature from '../components/feature-aura-network-app-overview'
import '../components/feature-aura-network-app-overview/style.less'

import smallKey from '../methods/smallKey'

import Graph1 from '../components/graphs/graph1'

import ArrowLeft from '../components/svg/arrow-left'
import ArrowRight from '../components/svg/arrow-right'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageSchema = {
  title: 'Aura Network',
  slug: '',
  abstract:
    'The Aura Network brings the ideals of Auroville as a human laboratory of transformation into a digital network that can actually realize its vision of human unity.',
  breadcrumbs: [{ title: 'Homepage', slug: '' }],
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query HomepageQuery {
    imgN1: file(relativePath: { eq: "img1.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 600
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    imgN2: file(relativePath: { eq: "img2.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 600
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    imgN3: file(relativePath: { eq: "img3.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 600
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    allAuraTalksJson(
      sort: { order: DESC, fields: publishedTimestamp }
      limit: 1
    ) {
      nodes {
        routeSlug
        remoteCover
      }
    }
    allAuraStoriesJson(
      sort: { order: DESC, fields: publishedTimestamp }
      limit: 6
    ) {
      nodes {
        title
        routeSlug
        quote
        cover {
          childImageSharp {
            gatsbyImageData(
              width: 900
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
    allEventsJson(sort: { order: DESC, fields: publishedTimestamp }, limit: 2) {
      edges {
        node {
          routeSlug
          title
          shortAbstract
          publishedTimestamp
        }
      }
    }
    g1: file(
      relativePath: { eq: "events/soft-launch-at-tibetan-pavillon/img24.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 600
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    g2: file(
      relativePath: { eq: "events/soft-launch-at-tibetan-pavillon/img20.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 600
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    g3: file(
      relativePath: { eq: "events/soft-launch-at-tibetan-pavillon/img23.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 600
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    g4: file(relativePath: { eq: "events/aura-fest/img4.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 600
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    g5: file(relativePath: { eq: "events/aura-fest/img54.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 600
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    g6: file(relativePath: { eq: "events/aura-fest/img16.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 600
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    s1: file(relativePath: { eq: "homepage2/img1.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    s2: file(relativePath: { eq: "homepage2/img2.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    s3: file(relativePath: { eq: "homepage2/img3.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    s4: file(relativePath: { eq: "homepage2/img4.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    s5: file(relativePath: { eq: "homepage2/img5.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    s6: file(relativePath: { eq: "homepage2/img6.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    hero: file(relativePath: { eq: "homepage2/hero.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    scw: file(relativePath: { eq: "homepage2/scw.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = (props) => {
  const { mediaState, data } = props
  let currentMediaStateWidth = 'isDesktop'
  let startLate = true
  if (isUndefined(mediaState) === false) {
    if (isUndefined(mediaState.currentWidth) === false) {
      currentMediaStateWidth = mediaState.currentWidth
      startLate = currentMediaStateWidth !== 'isMobile'
    }
  }

  const show = [1, 1, 2]

  const sliderConf = {
    slidesToScroll: 1,
    slidesToShow:
      currentMediaStateWidth === 'isMobile'
        ? show[0]
        : currentMediaStateWidth === 'isTablet'
        ? show[1]
        : show[2],
    infinite: true,
    dots: false,
    arrows: true,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
  }

  return (
    <StandardPageWrapper className="" pageSchema={pageSchema} {...props}>
      <section
        className="as-paragraph"
        style={{
          maxWidth: 'unset',
          borderRadius: '0.55rem',
          overflow: 'hidden',
          marginBottom: '1.1rem',
        }}
      >
        <div
          style={{
            position: 'relative',
            minHeight: 180,
          }}
        >
          <GatsbyImage
            style={{ minHeight: 180 }}
            objectFit="cover"
            objectPosition="66% bottom"
            image={getImage(props.data.hero)}
          />
          <p className="responsive-lead">
            <big>
              <big>
                From goodwill to action
                <br />
                Aura is the value
                <br />
                we create together
              </big>
            </big>
          </p>
        </div>
        <div
          style={{
            background: 'var(--gray-9)',
            paddingBottom: '1.1rem',
          }}
        >
          <div
            style={{
              position: 'relative',
              minHeight: 180,
            }}
          >
            <GatsbyImage
              style={{ minHeight: 180, opacity: 0 }}
              objectFit="cover"
              objectPosition="right bottom"
              image={getImage(props.data.hero)}
            />
            <p className="responsive-lead">
              <big>
                <big>
                  Aura is a digital currency that
                  <br />
                  fosters a circular economy
                  <br />
                  based on abundance and care
                </big>
              </big>
            </p>
          </div>
          <Row
            gutter={[
              { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
              { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
            ]}
          >
            <Col xs={8} sm={8} md={3} lg={3} xl={3} xxl={3}>
              <div className="center" style={{ paddingLeft: '0.55rem' }}>
                <GatsbyImage image={getImage(props.data.s1)} />
              </div>
            </Col>
            <Col xs={8} sm={8} md={5} lg={5} xl={5} xxl={5}>
              <div className="center" style={{ paddingRight: '0.225rem' }}>
                <GatsbyImage image={getImage(props.data.s2)} />
                <p
                  style={{
                    color: '#fff',
                    marginTop: '1.1rem',
                    marginBottom: '1.1rem',
                    fontWeight: 900,
                    textAlign: 'center',
                    fontFamily: 'var(--alt-text)',
                  }}
                >
                  Aura Income
                </p>
              </div>
            </Col>
            <Col xs={8} sm={8} md={3} lg={3} xl={3} xxl={3}>
              <div className="center" style={{ paddingRight: '0.55rem' }}>
                <GatsbyImage image={getImage(props.data.s3)} />
              </div>
            </Col>
            <Col xs={8} sm={8} md={5} lg={5} xl={5} xxl={5}>
              <div
                className="center"
                style={{ paddingLeft: '1.1rem', paddingRight: '1.1rem' }}
              >
                <GatsbyImage image={getImage(props.data.s4)} />
                <p
                  style={{
                    color: '#fff',
                    marginTop: '1.1rem',
                    marginBottom: '1.1rem',
                    fontWeight: 900,
                    textAlign: 'center',
                    fontFamily: 'var(--alt-text)',
                  }}
                >
                  Aura Network
                </p>
              </div>
            </Col>
            <Col xs={8} sm={8} md={3} lg={3} xl={3} xxl={3}>
              <div
                className="center"
                style={{ paddingLeft: '0.55rem', paddingRight: '0.55rem' }}
              >
                <GatsbyImage image={getImage(props.data.s5)} />
              </div>
            </Col>
            <Col xs={8} sm={8} md={5} lg={5} xl={5} xxl={5}>
              <div
                className="center"
                style={{ paddingLeft: '1.1rem', paddingRight: '1.1rem' }}
              >
                <GatsbyImage image={getImage(props.data.s6)} />
                <p
                  style={{
                    color: '#fff',
                    marginTop: '1.1rem',
                    marginBottom: '1.1rem',
                    fontWeight: 900,
                    textAlign: 'center',
                    fontFamily: 'var(--alt-text)',
                  }}
                >
                  Aura Value
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <section
        className="as-paragraph homepage-slider"
        style={{
          background: 'var(--gold1)',
          maxWidth: 'unset',
          padding: '1.1rem',
          borderRadius: '0.55rem',
          overflow: 'hidden',
          marginBottom: '1.1rem',
        }}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
            <h2>Meet members of the Aura Network!</h2>
          </Col>
          <Col xs={24} sm={24} md={18} lg={18} xl={18} xxl={18}>
            <div className="slider">
              <Carousel {...sliderConf}>
                {[
                  {
                    routeSlug: props.data.allAuraStoriesJson.nodes[0].routeSlug,
                    cover: props.data.allAuraStoriesJson.nodes[0].cover,
                    text: `${props.data.allAuraStoriesJson.nodes[0].quote} – ${props.data.allAuraStoriesJson.nodes[0].title}`,
                  },
                  {
                    routeSlug: props.data.allAuraStoriesJson.nodes[1].routeSlug,
                    cover: props.data.allAuraStoriesJson.nodes[1].cover,
                    text: `${props.data.allAuraStoriesJson.nodes[1].quote} – ${props.data.allAuraStoriesJson.nodes[1].title}`,
                  },
                  {
                    routeSlug: props.data.allAuraStoriesJson.nodes[2].routeSlug,
                    cover: props.data.allAuraStoriesJson.nodes[2].cover,
                    text: `${props.data.allAuraStoriesJson.nodes[2].quote} – ${props.data.allAuraStoriesJson.nodes[2].title}`,
                  },
                  {
                    routeSlug: props.data.allAuraStoriesJson.nodes[3].routeSlug,
                    cover: props.data.allAuraStoriesJson.nodes[3].cover,
                    text: `${props.data.allAuraStoriesJson.nodes[3].quote} – ${props.data.allAuraStoriesJson.nodes[3].title}`,
                  },
                  {
                    routeSlug: props.data.allAuraStoriesJson.nodes[4].routeSlug,
                    cover: props.data.allAuraStoriesJson.nodes[4].cover,
                    text: `${props.data.allAuraStoriesJson.nodes[4].quote} – ${props.data.allAuraStoriesJson.nodes[4].title}`,
                  },
                  {
                    routeSlug: props.data.allAuraStoriesJson.nodes[5].routeSlug,
                    cover: props.data.allAuraStoriesJson.nodes[5].cover,
                    text: `${props.data.allAuraStoriesJson.nodes[5].quote} – ${props.data.allAuraStoriesJson.nodes[5].title}`,
                  },
                ].map((resource) => (
                  <div className="slider-resource" key={smallKey()}>
                    <Link to={resource.routeSlug}>
                      <GatsbyImage
                        style={{ minHeight: '6rem', marginBottom: 0 }}
                        className="as-paragraph"
                        image={getImage(resource.cover)}
                      />
                      <p>{resource.text}</p>
                    </Link>
                  </div>
                ))}
              </Carousel>
            </div>
          </Col>
        </Row>
      </section>
      <section
        style={{
          maxWidth: 'unset',
          position: 'relative',
          borderRadius: '0.55rem',
          overflow: 'hidden',
          marginBottom: '1.1rem',
        }}
        className="diagram-of-joy as-paragraph"
      >
        <DiagramOfJoy />
      </section>
      <section
        className="as-paragraph app-slider"
        style={{
          background: 'var(--gray-2)',
          padding: '1.1rem',
          maxWidth: 'unset',
          borderRadius: '0.55rem',
          overflow: 'hidden',
          marginBottom: '1.1rem',
        }}
      >
        <h2>The Aura Network App</h2>
        <Row
          gutter={[
            { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
            { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
          ]}
        >
          <Col
            xs={24}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
            style={{ paddingRight: '0.55rem' }}
          >
            <p>
              The Aura Network App facilitates a care economy where the
              diversity of skills and talent from members of the network can
              cover the unique relative needs of others in the community.
            </p>
          </Col>
          <Col
            xs={0}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
            style={{ paddingLeft: '0.55rem' }}
          >
            <p>
              The network creates a space for a circular economy where things
              considered waste, or things that are not being purposed can be
              identified and then upcycled and repurposed.
            </p>
          </Col>
        </Row>
        <br />
        <br />
        <AuraNetworkAppOverviewFeature startLate={startLate} />
      </section>
      <section
        className="as-paragraph data-section"
        style={{
          background: 'var(--gold1)',
          maxWidth: 'unset',
          borderRadius: '0.55rem',
          overflow: 'hidden',
          position: 'relative',
          marginBottom: '1.1rem',
        }}
      >
        <h2 style={{ padding: '0.55rem 1.1rem', marginBottom: 0 }}>Insights</h2>
        <Graph1 />
        <Row
          gutter={[
            { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
            { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
          ]}
        >
          <Col
            xs={24}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
            className="responsive-col-padding n1"
          >
            What we have found is that our community is fundamentally
            inter-dependent and inter-arising, and that systems of the day need
            to serve everybody in the community.
          </Col>
          <Col
            xs={24}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
            className="responsive-col-padding n2"
          >
            The visualization you see here is titled "Flows" and represents the
            underlying pattern of unity we see in our data. Each ring represents
            a day, and each box a transaction.{' '}
            <Link to="/insights/flows">Read more.</Link>
          </Col>
        </Row>
      </section>
      <section
        className="as-paragraph"
        style={{
          background: '#fff',
          position: 'relative',
          maxWidth: 'unset',
          borderRadius: '0.55rem',
          overflow: 'hidden',
          marginBottom: '1.1rem',
        }}
      >
        <div
          className="auto-absolute"
          style={{
            top: '1.1rem',
            left: '1.1rem',
            zIndex: '100',
          }}
        >
          <h2
            style={{
              color: 'var(--gold2)',
            }}
          >
            In collaboration with Science Walden
          </h2>
          <p className="responsive-max-width">
            The Aura project is a collaborative project between{' '}
            <Link to="https://auroville.org/contents/1261">
              Korean Pavilion of Auroville
            </Link>
            and{' '}
            <Link to="http://sciencewalden.org/en">
              Science Walden Centre in South Korea
            </Link>
            , a convergence research centre at the{' '}
            <Link to="https://www.unist.ac.kr/">
              Ulsan National Institute of Science and Technology (UNIST)
            </Link>
            . <Link to="/the-story-of-aura">Read more.</Link>
          </p>
        </div>
        <GatsbyImage
          style={{
            height: '100%',
            width: '100%',
            zIndex: '10',
          }}
          objectFit="cover"
          image={getImage(props.data.scw)}
        />
      </section>
      {/* 
    <section className="as-paragraph" style={{ maxWidth: 'unset' }}>
      <h2>Aura Community</h2>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
          <h3>Aura Talks</h3>
          <img
            style={{ width: '100%', height: 'auto', display: 'block' }}
            src={props.data.allAuraTalksJson.nodes[0].remoteCover}
          />
          <p>
            Our podcast invites a diverse range of community members and
            explores with them the potentialities of Circular Basic Income in
            Auroville.
          </p>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
          <h3>Community Events</h3>
          <GatsbyImage image={getImage(props.data.g4)} />
          <p>
            blah blah blah blah blah blah blah blah blah blah blah blah blah
            blah blah blah...
          </p>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
          <h3>Aura Stories</h3>
          <GatsbyImage
            image={getImage(props.data.allAuraStoriesJson.nodes[0].cover)}
          />
          <p>
            Our video series showcasing the members of the Aura Network and
            their experiences on the platform.
          </p>
        </Col>
      </Row>
    </section>
    */}
    </StandardPageWrapper>
  )
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    mediaState: state.mediaState,
  })
  // dispatch => ({})
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedPage = compose(
  withState, // Add state
  pure // Make it a pure stateless functional component
)(Page)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default ComposedPage
