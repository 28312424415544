// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import compose from 'recompose/compose'
import pure from 'recompose/pure'

// import noop from 'lodash/noop'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { StaticQuery, graphql } from 'gatsby'

// import Popover from 'antd/lib/popover'
// import 'antd/lib/popover/style/css'

import Collapse from 'antd/lib/collapse'
import 'antd/lib/collapse/style/css'

// import { Spring, animated } from 'react-spring'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
// import Link from '../link'
// import '../link/style.less'

// import smallKey from '../../methods/smallKey'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React
const { Panel } = Collapse

export const query = graphql`
  query {
    bg: file(relativePath: { eq: "homepage2/bg.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    diagram: file(relativePath: { eq: "homepage2/diagram.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    joyOfOffering: file(relativePath: { eq: "homepage2/joy-of-offering.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    joyOfReceiving: file(
      relativePath: { eq: "homepage2/joy-of-receiving.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    joyOfSharing: file(relativePath: { eq: "homepage2/joy-of-sharing.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** DiagramOfJoy */
const DiagramOfJoy = () => (
  <StaticQuery
    query={query}
    render={(data) => (
      <Fragment>
        <Collapse accordion defaultActiveKey="1">
          <Panel
            header={
              <h2>
                <strong>See how a Care Economy works</strong>
              </h2>
            }
            key="1"
            showArrow={false}
            forceRender
          >
            <div className="overlay">
              <p>
                The Aura Network strives to empower the local economy by
                allowing community members to discover the abundance around
                them.
              </p>
            </div>
            <GatsbyImage
              style={{
                height: '120%',
                width: '100%',
                position: 'absolute',
                top: '-10%',
                left: '5%',
                zIndex: '200',
              }}
              objectFit="contain"
              objectPosition="right"
              image={getImage(data.diagram)}
              className="hidden-on-mobile"
            />
            <GatsbyImage
              style={{
                height: '100%',
                width: '100%',
                zIndex: '10',
              }}
              objectFit="cover"
              image={getImage(data.bg)}
              className="hidden-on-mobile"
            />
          </Panel>
          <Panel
            header={
              <h3>
                <strong>Joy of Receiving</strong>
              </h3>
            }
            key="2"
            forceRender
          >
            <div className="overlay">
              <p>
                A daily gift of Auras jump starts this economy that can’t
                possibly work, according to our usual assumptions.{' '}
                <span className="hidden-on-tablet">
                  Be surprised and delighted with our never-ending virtual
                  credit of Aura currency opening on our screen a prosperity of
                  goods and services available immediately. Most satisfying. Our
                  requests for these benefits instantly transacted on our phones
                  come to us really.{' '}
                  <span className="hidden-on-desktop hidden-on-large-desktop">
                    This actual abundance contributes to our self-worth and
                    contentment, Our goodwill and generosity are activated. Even
                    we can list our needs directly and receive according to what
                    others can offer, We want to play this game of sharing and
                    caring.
                  </span>
                </span>
              </p>
            </div>
            <GatsbyImage
              style={{
                height: '120%',
                width: '100%',
                position: 'absolute',
                top: '-10%',
                left: '5%',
                zIndex: '200',
              }}
              objectFit="contain"
              objectPosition="right"
              image={getImage(data.joyOfReceiving)}
              className="hidden-on-mobile"
            />
            <GatsbyImage
              style={{
                height: '100%',
                width: '100%',
                zIndex: '10',
              }}
              objectFit="cover"
              image={getImage(data.bg)}
              className="hidden-on-mobile"
            />
          </Panel>
          <Panel
            header={
              <h3>
                <strong>Joy of Offering</strong>
              </h3>
            }
            key="3"
            forceRender
          >
            <div className="overlay">
              <p>
                Giving may be even easier than receiving. As givers, we decide
                according to our preferences, abilities and generosity.{' '}
                <span className="hidden-on-tablet">
                  There’s the joy of making a difference, contributing to the
                  joy of others, and benefitting those with whom we share the
                  Aura Network. Our creativity has an unlimited field for
                  expression of what we want to offer as services or provide as
                  goods.{' '}
                  <span className="hidden-on-desktop hidden-on-large-desktop">
                    We feel fulfilled, appreciated, and valuable. Such
                    verification and affirmation as a contributor, helps us
                    expand and grow. We make the Aura Network flow.
                  </span>
                </span>
              </p>
            </div>
            <GatsbyImage
              style={{
                height: '120%',
                width: '100%',
                position: 'absolute',
                top: '-10%',
                left: '5%',
                zIndex: '200',
              }}
              objectFit="contain"
              objectPosition="right"
              image={getImage(data.joyOfOffering)}
              className="hidden-on-mobile"
            />
            <GatsbyImage
              style={{
                height: '100%',
                width: '100%',
                zIndex: '10',
              }}
              objectFit="cover"
              image={getImage(data.bg)}
              className="hidden-on-mobile"
            />
          </Panel>
          <Panel
            header={
              <h3>
                <strong>Joy of Sharing</strong>
              </h3>
            }
            key="4"
            forceRender
          >
            <div className="overlay">
              <p>
                Sharing is universally known to bring joy when it is done
                freely, especially in this significant way of providing an
                economy for all.{' '}
                <span className="hidden-on-tablet">
                  We are sharing on an egalitarian platform in a world that
                  becomes increasingly harsh and impoverished. We know our need
                  to share is essential for survival and that adds to the joy of
                  actually doing it, rather than waiting or hoping for it to
                  happen.
                  <span className="hidden-on-desktop hidden-on-large-desktop">
                    Sharing empowers givers and receivers, and we are both in
                    the Aura Network. Sharing enjoys the benefit of creating
                    community in a way that is supportive not only in goods and
                    service, but emotionally and spiritually. Sharing is the
                    basis of family and of society. The Aura journey brings is
                    back to this home.
                  </span>
                </span>
              </p>
            </div>
            <GatsbyImage
              style={{
                height: '120%',
                width: '100%',
                position: 'absolute',
                top: '-10%',
                left: '5%',
                zIndex: '200',
              }}
              objectFit="contain"
              objectPosition="right"
              image={getImage(data.joyOfSharing)}
              className="hidden-on-mobile"
            />
            <GatsbyImage
              style={{
                height: '100%',
                width: '100%',
                zIndex: '10',
              }}
              objectFit="cover"
              image={getImage(data.bg)}
              className="hidden-on-mobile"
            />
          </Panel>
        </Collapse>
      </Fragment>
    )}
  />
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedDiagramOfJoy = compose(
  pure // Wrap it with pure HOC
)(DiagramOfJoy)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default ComposedDiagramOfJoy
