// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import compose from 'recompose/compose'
import pure from 'recompose/pure'

import slice from 'lodash/slice'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { StaticQuery, graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Slider from '../slider'
import '../slider/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

export const query = graphql`
  query {
    flow1: file(relativePath: { eq: "homepage/flow1T.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    flow2: file(relativePath: { eq: "homepage/flow2T.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    flow3: file(relativePath: { eq: "homepage/flow3T.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    flow4: file(relativePath: { eq: "homepage/flow4T.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    flow5: file(relativePath: { eq: "homepage/flow5T.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    flow6: file(relativePath: { eq: "homepage/flow6T.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    flow7: file(relativePath: { eq: "homepage/flow7T.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    flow8: file(relativePath: { eq: "homepage/flow8T.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    flow9: file(relativePath: { eq: "homepage/flow9T.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    flow10: file(relativePath: { eq: "homepage/flow10T.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    flow11: file(relativePath: { eq: "homepage/flow11T.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    flow12: file(relativePath: { eq: "homepage/flow12T.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    flow13: file(relativePath: { eq: "homepage/flow13T.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Feature */
const Feature = ({ startLate }) => (
  <StaticQuery
    query={query}
    render={(data) => {
      let resources = [
        {
          title: ' ',
          cover: data.flow111,
          text: ' ',
        },
        {
          title: 'Network',
          cover: data.flow3,
          text: '#1. Get an overview of everyone on the network.',
        },
        {
          title: 'Browse Offers',
          cover: data.flow6,
          text: '#2. Discover what your community members are sharing.',
        },
        {
          title: 'Browse Needs',
          cover: data.flow7,
          text: '#3. Explore how you could help your community members.',
        },
        {
          title: 'View Offers',
          cover: data.flow5,
          text: '#4. Interested in an offer? Check details of an offering.',
        },
        {
          title: 'Post an Offer',
          cover: data.flow1,
          text: '#5. Experience the joy of giving and sharing on the Network.',
        },
        {
          title: 'Post a Need',
          cover: data.flow2,
          text:
            '#6. Experience the joy of receiving and engaging on the Network.',
        },
        {
          title: 'Transact Via Chat',
          cover: data.flow9,
          text: '#7. Make inquiries about offers and needs, and send Auras.',
        },
        {
          title: 'Transfer Auras',
          cover: data.flow12,
          text: '#8. Contribute Auras and create value.',
        },
        {
          title: 'Aura Account',
          cover: data.flow4,
          text:
            '#9. Get an overview of the value you have created with your Auras.',
        },
        {
          title: 'Your Profile',
          cover: data.flow8,
          text: '#10. Introduce yourself to the community!',
        },
        {
          title: 'Other Members',
          cover: data.flow11,
          text: '#11. Learn more about a member on their profile page!',
        },
        {
          title: 'Aura Peers',
          cover: data.flow13,
          text: '#12. Share Auras with your peers in the community.',
        },
        {
          title: 'Qs & Reviews',
          cover: data.flow10,
          text: '#13. Ask more questions and check reviews.',
        },
      ]

      if (startLate === false) {
        resources = slice(resources, 1)
      }

      return (
        <Slider
          show={[1, 2, 3]}
          resources={resources}
          type="standard homepage"
        />
      )
    }}
  />
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedFeature = compose(
  pure // Wrap it with pure HOC
)(Feature)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default ComposedFeature
